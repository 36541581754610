import request from "@/utils/request"

// 登录
export function login(data) {
  return request({
    url: "/auth/v1/login",
    method: "post",
    data
  })
}
//获取验证码
export function getSms(query) {
  return request({
    url: "/auth/v1/getSms",
    method: "get",
    params:query
  })
}
