<template>
  <div class="logo">
    <div class="logo_img">
      <img
        src="https://number-of-iq-1300257084.cos.ap-nanjing.myqcloud.com/iq-dada/202301081555.png"
        alt=""
      />
    </div>
    <div class="logo_info">
      <div class="logo_name">
        <img src="../../../assets/image/数智秘书长运营后台.png" alt="" />
      </div>
      <el-form
        ref="ruleForm"
        :model="loginInfo"
        :rules="rules"
        :hide-required-asterisk="true"
        :show-message="false"
        label-width="80px"
        class="form"
      >
        <el-form-item label="手机号：" prop="mobile">
          <el-input
            placeholder="请输入手机号"
            v-model="loginInfo.mobile"
            class="login-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码：" prop="smsCode">
          <div class="bind_code">
            <el-input
              type="text"
              placeholder="请输入验证码"
              v-model="loginInfo.smsCode"
              class="login-input"
              @keyup.enter.native="onSubmit"
            >
            </el-input>
            <el-button
              type="primary"
              plain
              @click.native.prevent="bindforgetSendCode"
              class="codebtn"
              :disabled="disabled"
            >
              {{ btnText }}
            </el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="buttom" type="success" @click="onSubmit">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login, getSms } from '@/api/login'
export default {
  data() {
    return {
      loginInfo: {},
      pwdType: 'password',
      eyeType: 'icon-jr-icon-eye',
      btnText: '获取验证码',
      disabled: false,
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入手机号', trigger: 'blur' }
        ],
        smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },
  methods: {
    //发送验证码
    bindforgetSendCode() {
      this.$refs['ruleForm'].validateField('mobile', (errorMessage) => {
        if (!errorMessage) {
          getSms({ phone: this.loginInfo.mobile }).then((res) => {
            if (res.data.resultCode == 200) {
              this.disabled = true
              this.btnText = '请稍候...'
              setTimeout(() => {
                this.doloop(60)
              }, 500)
              this.$message.success(res.data.message)
            } else {
              this.$message.error(res.data.message)
            }
          })
        } else {
          this.$message.error('请输入正确的手机号！')
          return false
        }
      })
    },
    //验证码倒计时
    doloop(seconds) {
      seconds = seconds ? seconds : 60
      this.btnText = seconds + 's后获取'
      let countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = seconds + 's后获取'
          --seconds
        } else {
          this.btnText = '获取验证码'
          this.disabled = false
          clearInterval(countdown)
        }
      }, 1000)
    },

    //登录
    async onSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          login(this.loginInfo).then((response) => {
            if (response.data.resultCode === 200) {
              const token = response.headers.token
              sessionStorage.setItem('token', token)
              sessionStorage.setItem('userId', response.data.data.id)
              sessionStorage.setItem('userName', response.data.mobile)
              this.$message.success(response.data.message)
              this.$router.replace({
                path: '/'
              })
            } else {
              this.$message.error(response.data.message)
            }
          })
        } else {
          this.$message.error('请输入手机号跟验证码！')
        }
      })
    },
    changeType() {
      this.pwdType = this.pwdType === 'password' ? 'text' : 'password'
      this.eyeType = this.pwdType === 'password' ? 'icon-jr-icon-eye' : 'icon-jr-icon-close'
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-input__inner:focus,
.el-form-item.is-error .el-textarea__inner,
.el-form-item.is-error .el-textarea__inner:focus {
  border-color: #448aff;
}
::v-deep .el-form-item__content {
  width: 300px;
  line-height: 40px;
  position: relative;
  font-size: 14px;
}

.logo {
  width: 100%;
  height: 99vh;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .bind_code {
    position: relative;
    .codebtn {
      position: absolute;
      right: 6px;
      font-size: 12px;
      height: 30px;
      line-height: 6px;
      top: 5px;
      width: 70px;
      border: 1px solid #ffffff;

      display: flex;
      justify-content: center;
    }
  }
  .logo_info {
    padding: 0px 100px 0px 50px;
    .logo_name {
      position: relative;
      top: -50px;
      img {
        width: 597px;
        height: 50px;
      }
    }
  }

  .logo_img {
    width: 981px;
    height: 100vh;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .logo_samll {
    position: absolute;
    left: 50px;
    top: 30px;
    width: 348px;
    height: 94px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .top_txt {
    padding: 70px 20px;
    margin-left: -71px;
    width: 559px;
    height: 52px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .buttom {
    width: 300px;
    background: #4e93fb;
    border: 1px solid #4e93fb;
  }

  .form {
    .login-input {
      width: 300px;
    }
  }
}
</style>
